// import { rIC } from 'idlize/idle-callback-polyfills';
import Headroom from 'headroom.js';

class TopBar {
  constructor({
    // Default options.
    target = '.top-bar',
  } = {}) {
    this.target = document.querySelector(target);
    this.headroomInstance = null;
  }

  // TODO: Use this when rIC works.

  // mountOptimized() {
  //   rIC(() => {
  //     if (this.topBar) {
  //       this.headroomInstance = new Headroom(this.target, {
  //         offset: 300,
  //         tolerance: 5,
  //       });

  //       this.headroomInstance.init();
  //     }
  //   });
  // }

  mount() {
    if (this.target) {
      this.headroomInstance = new Headroom(this.target, {
        offset: {
          down: 300,
          up: 1,
        },
        tolerance: 5,
      });

      this.headroomInstance.init();
    }
  }
}

function init() {
  new TopBar().mount();
}

export default {
  init,
};
