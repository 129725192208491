// Modern Javascript file for new browsers.
// Build will include less polyfills etc than "legacy" version.

import 'babel-polyfill';
import 'intersection-observer';
import 'focus-visible';
import 'element-qsa-scope';
//import 'alpinejs';

import focusWithinPolyfill from 'focus-within';
import smoothScrollPolyfill from 'smoothscroll-polyfill';

import navPanel from '../../components/nav-panel/nav-panel';
import navMobile from '../../components/nav-mobile/nav-mobile';
import topBar from '../../components/top-bar/top-bar';
import lazyLoad from '../../components/lazy-load/lazy-load';
import gravityForms from '../../components/gravity-forms/gravity-forms';
import magnificPopup from '../../components/magnific-popup/magnific-popup';
//import teaserCountdown from '../../blocks/teaser-countdown/teaser-countdown';
import langSwitcher from '../../components/lang-switcher/lang-switcher';
// import { debounce } from 'lodash-es';

const appStart = () => {

  // Make WP-core jQuery available globally
  window.$ = window.jQuery;

  focusWithinPolyfill(document);
  smoothScrollPolyfill.polyfill();

  navPanel.init();
  navMobile.init();
  topBar.init();
  lazyLoad.init();
  gravityForms.init();
  magnificPopup.init();
  //teaserCountdown.init();
  langSwitcher.init();
};

// 'DOMContentLoaded' may fire before your script has a chance to run.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', appStart);
} else {
  appStart();
}
